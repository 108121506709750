import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { useIntl } from "gatsby-plugin-react-intl"
import RequestButton from "../components/RequestButton"

const TeamTemplate = ({ data: { teamMember }, location }) => {
  const intl = useIntl()

  return (
    <Layout>
      <h1 className="hidden">{teamMember?.title}</h1>
      <Seo seoData={teamMember?.seo} />

      <div className="w-10/12 mx-auto text-themePrimary mt-12 mb-5">
        <div>
          <h2 className="font-PTSerif font-bold text-5xl text-themePrimary pb-3">
            {teamMember.title}
          </h2>
          <GatsbyImage
            alt={teamMember.featuredImage.node.altText}
            image={
              teamMember.featuredImage.node.localFile.childImageSharp
                .gatsbyImageData
            }
          />
          <div className="pt-8 pb-5 prose md:prose-lg max-w-none text-justify">
            {intl.locale === "en"
              ? parse(teamMember?.content)
              : parse(teamMember?.spanishBio?.spanishBio)}
          </div>

          <RequestButton />
        </div>
      </div>
    </Layout>
  )
}

export default TeamTemplate

export const pageQuery = graphql`
  query TeamPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    teamMember: wpTeamMember(id: { eq: $id }) {
      id
      content
      title
      memberRole
      spanishBio {
        spanishBio
        spanishRole
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      seo {
        fullHead
        schema {
          raw
        }
      }
    }
  }
`
